import React, { useContext, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../store';
import { ACTIONS_TYPES, LOCAL_STORAGE_KEYS } from '../constants';
import { useCartItems, useItemRecommendations, useShowToast } from '../hooks';
import { sessionCacheStorage, storageService } from '../services';
import {
  Breadcrumbs,
  InfiniteScroll,
  ProductsItem,
  ShoppingCartItems,
  ShoppingCartSubtotal,
} from '../components';
import { getLocalStoreFrontSlug, getShoppingCartPageForGoogleAnalytics } from '../utils';

const RecommendationsWidget = () => {
  const { itemRecommendations, fetchListMore, hasMore } = useItemRecommendations();

  return itemRecommendations?.length ? (
    <div>
      <h1 className="my-10 text-2xl font-bold">Recommendations</h1>
      <InfiniteScroll
        itemComponent={ProductsItem}
        data={itemRecommendations}
        fetchListMore={fetchListMore}
        hasMore={hasMore}
      />
    </div>
  ) : null;
};

export const CartPage = () => {
  const { state, dispatch } = useContext(Context);
  const { cartItems, totalAmount, totalSum } = useCartItems(state.cart);
  const history = useHistory();
  const { showError } = useShowToast();
  const storeFrontSlug = getLocalStoreFrontSlug();

  const removeFromCart = (uid) => {
    dispatch({ type: ACTIONS_TYPES.REMOVE_FROM_CART, payload: uid });
  };

  const incrementAmount = useCallback(
    (uid) => {
      dispatch({ type: ACTIONS_TYPES.INCREMENT_AMOUNT, payload: uid });
    },
    [dispatch],
  );

  const decrementAmount = useCallback(
    (uid) => {
      dispatch({ type: ACTIONS_TYPES.DECREMENT_AMOUNT, payload: uid });
    },
    [dispatch],
  );

  const checkErrorAvailability = () => {
    const isError = storageService.getItem(LOCAL_STORAGE_KEYS.isError);

    if (isError) {
      showError(
        "Some of the products you selected are no longer available in the specified quantity. That's why we've edited your Shopping Cart so you can create a new order",
      );
      storageService.removeItem(LOCAL_STORAGE_KEYS.isError);
    }
  };

  useEffect(() => {
    checkErrorAvailability();
    getShoppingCartPageForGoogleAnalytics(window);
  }, []);

  return (
    <div className="container mx-auto">
      <Breadcrumbs catalogName="Shopping cart" />

      <h1 className="my-5 text-center text-3xl font-bold text-black">Shopping cart</h1>

      <div className="grid grid-cols-1 gap-y-6 lg:grid-cols-3 lg:gap-6">
        <div className="col-span-3 lg:col-span-2">
          <ShoppingCartItems
            cartItems={cartItems}
            incrementAmount={incrementAmount}
            decrementAmount={decrementAmount}
            removeFromCart={removeFromCart}
            totalAmount={totalAmount}
            totalSum={totalSum}
          />
        </div>

        <div className="col-start-1 row-start-2 md:col-start-3 lg:row-start-1">
          <ShoppingCartSubtotal
            onClick={() => history.push(`/${storeFrontSlug}/cart/checkout`)}
            totalAmount={totalAmount}
            totalSum={totalSum}
            buttonText="Proceed to checkout"
            idButton="proceed-to-checkout"
          />
        </div>
      </div>

      {sessionCacheStorage.hasToken && <RecommendationsWidget />}
    </div>
  );
};
