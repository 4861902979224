import * as Yup from 'yup';
import { MATCH_NAME, STATE_CODES } from '../constants';

export const useValidationShippingSchema = () =>
  Yup.object().shape(
    {
      firstName: Yup.string()
        .min(3, 'Invalid name')
        .matches(MATCH_NAME, {
          excludeEmptyString: true,
          message: 'Invalid name',
        })
        .trim()
        .required('Required'),
      lastName: Yup.string()
        .min(3, 'Invalid last name')
        .matches(MATCH_NAME, {
          excludeEmptyString: true,
          message: 'Invalid name',
        })
        .trim()
        .required('Required'),
      email: Yup.string().email('Invalid email').trim().required('Required'),
      addressLine1: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      zipCode: Yup.string()
        .required('Required')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits'),
      state: Yup.string().required('Required').oneOf(STATE_CODES, 'Invalid State Code'),
      country: Yup.string().required('Required'),
      phone: Yup.string()
        .test('isValidPhone', 'Invalid phone', (value) => {
          const formattedValue = value?.replace(/[\s()_]/g, '') || '';
          if (!value || formattedValue.length === 2) {
            return true;
          }
          return formattedValue.length === 12;
        })
        .when('orderNotificationsType', {
          is: 'sms',
          then: (schema) =>
            schema.test('isValidPhone', 'Invalid phone', (value) => {
              const formattedValue = value?.replace(/[\s()_]/g, '') || '';
              return formattedValue.length === 12;
            }),
        }),
      orderNotificationsType: Yup.string()
        .required('Required')
        .oneOf(['sms', 'email'], 'Invalid notification method'),
    },
    [['phone', 'phone']],
  );
