class StorageService {
  APP_PREFIX = 'store_front_';

  get isAvailable() {
    try {
      window.localStorage.setItem('key', 'value');
      return true;
    } catch {
      return false;
    }
  }

  setItem(key, value) {
    if (this.isAvailable) {
      localStorage.setItem(`${this.APP_PREFIX}${key}`, JSON.stringify(value));
    }
  }

  getItem(key) {
    if (!this.isAvailable) {
      return null;
    }

    const values = localStorage.getItem(`${this.APP_PREFIX}${key}`);

    return JSON.parse(values ?? null);
  }

  removeItem(key) {
    if (!this.isAvailable) {
      return;
    }

    localStorage.removeItem(`${this.APP_PREFIX}${key}`);
  }
}

export const storageService = new StorageService();
