import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Divider, Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { formSchema } from './form-schema';
import { authViewStates } from '../../constants/auth-view-states';
import { Button } from '../button';
import { AuthFormFooter } from '../auth-form-footer';
import { ConsoleLink } from '../footer/console-link';

const useStyles = makeStyles(() => ({
  formTitle: {
    margin: '0 0 14px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  formSubTitle: {
    margin: '14px 0 36px',
    color: 'var(--gray)',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
  },
  input: {
    width: '100%',
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  form: {
    width: '100%',
  },
  asterisk: {
    color: 'red',
  },
  divider: {
    marginTop: '20px',
  },
}));

export const SignInForm = ({ email, changeState, handleLoginAccount }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: email ?? '',
    },
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    handleLoginAccount(data);
  };

  return (
    <Grid container justifyContent="center">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={1} width="100%" justifyContent="center" align="center">
          <Grid item xs={12} sm={12} md={12}>
            <p className={classes.formTitle}>Welcome</p>
            <p className={classes.formSubTitle}>Please Log In to your Account</p>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputTextField
                      fullWidth
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      label="Email"
                      InputLabelProps={{
                        required: true,
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      inputProps={{ className: classes.input }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Button type="submit" variant="contained" disabled={!isValid}>
              Send magic link
            </Button>

            <AuthFormFooter
              description="Don't have an account?"
              labelButton="Sign Up"
              onChangeState={() => changeState(authViewStates.signUp)}
            />

            <Divider className={classes.divider} />
            <AuthFormFooter
              description="Are you the Owner of this Storefront?"
              labelButton={<ConsoleLink description="Sign in Here" />}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
