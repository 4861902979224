import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import { InputTextField } from '../input-text-field';
import { useCouponCodeForm } from '../../hooks';
import { Button } from '../button';

const useStyles = makeStyles((theme) => ({
  formTitle: {
    color: theme.palette.gray.dark,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  input: {
    textTransform: 'uppercase',
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  asterisk: {
    color: 'red',
  },
}));

export const CouponCodeForm = ({ onApplyCouponCode }) => {
  const classes = useStyles();
  const { control, handleSubmit } = useCouponCodeForm({
    onApplyCouponCode,
  });

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <p className={classes.formTitle}>
                If you have a coupon code please enter and apply it below.
              </p>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Coupon Code"
                    InputLabelProps={{
                      classes: {
                        asterisk: classes.asterisk,
                      },
                    }}
                    inputProps={{ className: classes.input }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                type="submit"
                size="action"
                color="primary"
                className={classes.button}
                variant="contained"
              >
                APPLY
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
