import { API_HEADERS } from '../constants';

export const getMarketplaceHeader = () => {
  const hostname = window?.location?.hostname?.split('.');

  return hostname?.length
    ? {
        [API_HEADERS.marketplace]:
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_LOCAL_MARKETPLACE_SLUG
            : hostname[0],
      }
    : {};
};
