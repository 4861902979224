import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { makeStyles } from '@material-ui/core/styles';
import { InputTextField } from '../input-text-field';
import { formSchema } from './form-schema';
import { authViewStates } from '../../constants/auth-view-states';
import { Button } from '../button';
import { AuthFormFooter } from '../auth-form-footer';

const useStyles = makeStyles(() => ({
  formTitle: {
    margin: '0 0 30px',
    color: 'var(--dark-gray)',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  form: {
    width: '100%',
  },
  asterisk: {
    color: 'red',
  },
}));

const initialValueFields = {
  name: '',
  email: '',
};

export const SignUpForm = ({ changeState, handleCreateAccount }) => {
  const classes = useStyles();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    defaultValues: initialValueFields,
    resolver: yupResolver(formSchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    handleCreateAccount(data);
  };

  return (
    <Grid container justifyContent="center">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid container spacing={1} justifyContent="center" align="center">
          <Grid item xs={12} sm={12} md={12}>
            <p className={classes.formTitle}>Sign Up</p>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <InputTextField
                      fullWidth
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      label="Name"
                      InputLabelProps={{
                        required: true,
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      inputProps={{ className: classes.input }}
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <InputTextField
                      fullWidth
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      label="Email"
                      InputLabelProps={{
                        required: true,
                        classes: {
                          asterisk: classes.asterisk,
                        },
                      }}
                      inputProps={{ className: classes.input }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              variant="contained"
              disabled={!isValid || !Object.keys(dirtyFields).length}
            >
              Create Account
            </Button>

            <AuthFormFooter
              description="Already have an account?"
              labelButton="Log In"
              onChangeState={() => changeState(authViewStates.signIn)}
            />
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
