import React from 'react';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { displayCentsAsDollars, setPlaceholderImageOnError } from '../../utils';
import { ProductCartAmount } from '../product-cart-amount';

const useStyles = makeStyles((theme) => ({
  cartRow: {
    borderBottom: '1px solid var(--light-gray)',
    display: 'grid',
    gridTemplateColumns: '80px 45px 1fr 1fr',
    gridTemplateAreas: `
                    'image name name name'
                     'image price quantity totalPrice'
                     'returnQuantity returnQuantity returnQuantity returnQuantity'
                `,
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: 'unset',
      gridTemplateColumns: '80px 1fr 80px 90px 90px 90px',
    },
    gridGap: '20px',
    position: 'relative',
    alignItems: 'center',
    padding: theme.spacing(4, 0),
    '&:last-child': {
      borderBottom: '1px solid transparent',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '15px',
      '&:last-child': {
        borderBottom: '1px solid var(--light-gray)',
      },
    },
  },
  cartHeader: {
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 80px 90px 90px 90px',
    },
    gridGap: '0',
    paddingBottom: '7px',
    gridColumnGap: '20px',
    '& > span': {
      textAlign: 'center',
      fontSize: '10px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        lineHeight: '12px',
      },
    },
  },
  image: {
    width: '80px',
    height: '80px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'image',
    },
  },
  name: {
    fontSize: '15px',
    lineHeight: '18px',
    color: 'var(--dark-gray)',
    textTransform: 'uppercase',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%',
      fontSize: '13px',
    },
  },
  names: {
    [theme.breakpoints.down('xs')]: {
      gridArea: 'name',
    },
  },
  totalPrice: {
    color: theme.palette.primary.main,
    fontSize: '15px',
    fontWeight: 700,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'totalPrice',
    },
  },
  category: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  price: {
    fontSize: '15px',
    fontWeight: 700,
    color: 'var(--dark-gray)',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'price',
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'center',
    },
  },
  quantity: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 700,
    color: 'var(--dark-gray)',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'quantity',
    },
  },
  returnQuantity: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 700,
    color: 'var(--dark-gray)',
    [theme.breakpoints.down('xs')]: {
      gridArea: 'returnQuantity',
      justifyContent: 'end',
    },
  },
  total: {
    fontWeight: 600,
    fontSize: '13px',
    textAlign: 'right',
    '& > span': {
      display: 'inline-block',
    },
    '& > span:last-child': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      fontWeight: 700,
      minWidth: '90px',
      textAlign: 'center',
      marginLeft: '20px',
      marginTop: '20px',
    },
  },
  headerPrice: {
    textAlign: 'left!important',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center!important',
    },
  },
}));

export const RequestReturnItems = ({ items, incrementAmount, decrementAmount }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={8}>
      <div className={`${classes.cartRow} ${classes.cartHeader}`}>
        <span />
        <span className={classes.headerPrice}>Price</span>
        <span>Qty:</span>
        <span>Sum</span>
        <Hidden smDown>
          <span>Return Qty:</span>
        </Hidden>
      </div>

      {items?.length ? (
        items.map((item) => (
          <div key={item.uid} className={classes.cartRow}>
            <div className={classes.image}>
              <img src={item.image} alt={item.name} onError={setPlaceholderImageOnError} />
            </div>
            <div className={classes.names}>
              <h3 className={classes.name}>{item.name}</h3>
              <h5 className={classes.category}>{item?.category?.name}</h5>
            </div>
            <span className={classes.price}>{displayCentsAsDollars(item.price)}</span>
            <div className={classes.quantity}>{item.qty}</div>
            <div className={classes.totalPrice}>
              {displayCentsAsDollars(item.amount * item.price)}
            </div>
            <div className={classes.returnQuantity}>
              <ProductCartAmount
                small
                value={item.amount}
                minValue={0}
                increment={() => incrementAmount(item.uid)}
                decrement={() => decrementAmount(item.uid)}
                maxQuantity={item.qty}
                availabilityControl
              />
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </Grid>
  );
};
