import React, { useContext, useEffect, useImperativeHandle, useState } from 'react';
import { FormControlLabel, FormLabel, Grid, List, ListItem, RadioGroup } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import { InputTextField } from '../input-text-field';
import { useGeocodeByAddress, useValidationShippingSchema } from '../../hooks';
import { getAddressData } from '../../utils';
import { Context } from '../../store';
import { RadioButton } from '../radio-button';

const useStyles = makeStyles((theme) => ({
  wrapperField: {
    position: 'relative',
  },
  wrapperList: {
    top: '100%',
    position: 'absolute',
    zIndex: '10',
    width: '100%',
    background: theme.palette.white.main,
  },
  list: {
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '18px',
    '& .MuiListItem-root': {
      paddingTop: '0',
      paddingBottom: '0',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.6',
      },
    },
  },
  formTitle: {
    color: theme.palette.gray.dark,
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'bold',
    margin: '0 0 10px',
  },
  formSmallTitle: {
    color: theme.palette.gray.dark,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
  },
  divider: {
    marginBottom: '10px',
  },
  radioLabel: {
    color: theme.palette.gray.dark,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 'bold',
    position: 'relative',
    '& span': {
      color: theme.palette.gray.main,
      fontSize: '10px',
      fontWeight: 'normal',
    },
  },
  input: {
    WebkitBoxShadow: '0 0 0 1000px white inset !important',
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
    '&:disabled': {
      WebkitBoxShadow: '0 0 0 1000px white inset !important',
    },
  },
  asterisk: {
    color: 'red',
  },
}));

export const ShippingForm = ({ onSubmit, formRef = null, checkError, account }) => {
  const { state } = useContext(Context);
  const classes = useStyles();
  const validationSchema = useValidationShippingSchema();
  const { getGeocodeByAddress } = useGeocodeByAddress();
  const [isFocusAutocomplete, setFocusAutocomplete] = useState(false);

  const defaultAddress = account?.addresses?.[0] || {};

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
    trigger,
  } = useForm({
    defaultValues: {
      firstName: account?.contact?.formalName?.split(' ')[0] || '',
      lastName: account?.contact?.formalName?.split(' ')[1] || '',
      email: account?.contact?.email || '',
      phone: account?.contact?.phone || '',
      addressLine1: defaultAddress.streetLine1 || '',
      addressLine2: defaultAddress.streetLine2 || '',
      city: defaultAddress.city || '',
      zipCode: defaultAddress.postalCode || '',
      state: defaultAddress.stateCode || '',
      country: process.env.REACT_APP_FILTER_BY_COUNTRY || 'US',
      orderNotificationsType: account?.config?.orderNotificationsType || 'email',
    },
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  useEffect(() => {
    trigger('phone');
  }, [watch('orderNotificationsType')]);

  useEffect(() => {
    checkError(isValid);
  }, [isValid]);

  useImperativeHandle(formRef, () => ({
    handleSubmit: handleSubmit(onSubmit),
    reset: () => formRef.current.reset(),
    trigger: () => trigger(),
  }));

  const getShortAddress = (shippingAddress) => {
    if (shippingAddress?.street_number && shippingAddress?.route) {
      return `${shippingAddress.street_number.long_name} ${shippingAddress.route.long_name}`;
    }
    if (shippingAddress?.route) {
      return shippingAddress?.route.long_name;
    }
    return '';
  };

  const onFocusAutocomplete = () => {
    setFocusAutocomplete(true);
  };

  const onBlurAutocomplete = (e, handlerBlur) => {
    handlerBlur(e);
    setFocusAutocomplete(false);
  };

  const handleSelect = async (address, fieldName, setFieldValue) => {
    const data = await getGeocodeByAddress(address);

    const shippingAddress = getAddressData(data?.addressComponents);
    const shortAddress = getShortAddress(shippingAddress);
    setFieldValue(fieldName, shortAddress);

    const { locality, postal_code, administrative_area_level_1, administrative_area_level_2 } =
      shippingAddress;

    if (locality) {
      setFieldValue('city', locality.long_name);
    } else {
      setFieldValue('city', '');
    }

    if (administrative_area_level_1 || administrative_area_level_2) {
      setFieldValue(
        'state',
        administrative_area_level_1?.short_name || administrative_area_level_2?.short_name,
      );
    } else {
      setFieldValue('state', '');
    }

    if (postal_code) {
      setFieldValue('zipCode', postal_code.long_name);
    } else {
      setFieldValue('zipCode', '');
    }
    trigger();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <p className={classes.formTitle}>Contact</p>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    label="First Name"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'firstName',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    label="Last Name"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'lastName',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    label="Email"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'email',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputMask
                    {...field}
                    alwaysShowMask
                    mask="+1 (999) 999 9999"
                    maskChar="_"
                    className="input-mask"
                  >
                    {(inputProps) => (
                      <InputTextField
                        {...inputProps}
                        fullWidth
                        variant="outlined"
                        error={!!errors.phone}
                        helperText={errors.phone?.message}
                        label="Mobile Phone Number"
                        InputLabelProps={{
                          required: watch('orderNotificationsType') === 'sms',
                          classes: { asterisk: classes.asterisk },
                        }}
                        inputProps={{
                          className: classes.input,
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <p className={classes.formTitle}>Shipping address</p>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="addressLine1"
                control={control}
                render={({ field }) => (
                  <PlacesAutocomplete
                    value={field.value}
                    onChange={field.onChange}
                    onSelect={(value) => handleSelect(value, 'addressLine1', setValue)}
                    onBlur={(e) => onBlurAutocomplete(e, field.onBlur)}
                    searchOptions={{
                      componentRestrictions: {
                        country: state?.config?.checkout?.allowedCountries ?? ['US'],
                      },
                      types: ['address'],
                    }}
                    shouldFetchSuggestions={isFocusAutocomplete}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className={classes.wrapperField}>
                        <InputTextField
                          fullWidth
                          variant="outlined"
                          error={!!errors.addressLine1}
                          helperText={errors.addressLine1?.message}
                          label="Street Address"
                          InputLabelProps={{
                            className: classes.input,
                            required: true,
                            classes: { asterisk: classes.asterisk },
                          }}
                          inputProps={{
                            'data-testid': 'addressLine1',
                            className: classes.input,
                            onBlur: (e) => onBlurAutocomplete(e, field.onBlur),
                            onFocus: () => onFocusAutocomplete(),
                          }}
                          {...getInputProps()}
                        />
                        {isFocusAutocomplete && (
                          <div className={classes.wrapperList}>
                            {loading && <div>Loading...</div>}
                            {suggestions.length ? (
                              <List className={classes.list}>
                                {suggestions.map((suggestion) => (
                                  <ListItem
                                    key={suggestion.index}
                                    {...getSuggestionItemProps(suggestion)}
                                  >
                                    <ListItemText primary={suggestion.description} />
                                  </ListItem>
                                ))}
                              </List>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="addressLine2"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.addressLine2}
                    helperText={errors.addressLine2?.message}
                    label="Address Line 2"
                    InputLabelProps={{
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'addressLine2',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.city}
                    helperText={errors.city?.message}
                    label="City"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'city',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.state}
                    helperText={errors.state?.message}
                    label="State"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'state',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    error={!!errors.zipCode}
                    helperText={errors.zipCode?.message}
                    label="Zip Code"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'zipCode',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <InputTextField
                    {...field}
                    fullWidth
                    disabled
                    variant="outlined"
                    error={!!errors.country}
                    helperText={errors.counry?.message}
                    label="Country Code"
                    InputLabelProps={{
                      required: true,
                      classes: { asterisk: classes.asterisk },
                    }}
                    inputProps={{
                      'data-testid': 'country',
                      className: classes.input,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormLabel component="legend" className={classes.formTitle}>
                Notification method
              </FormLabel>

              <Controller
                name="orderNotificationsType"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="email"
                      control={<RadioButton />}
                      label={<div className={classes.radioLabel}>Email only</div>}
                    />
                    <FormControlLabel
                      value="sms"
                      control={<RadioButton />}
                      label={
                        <div className={classes.radioLabel}>
                          Email and SMS <span>( * Message & Data Rates May Apply )</span>
                        </div>
                      }
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
