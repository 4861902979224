import { gql } from '@apollo/client';

export const ME_ACCOUNT_QUERY = gql`
  query meAccount {
    me {
      addresses {
        city
        countryCode
        postalCode
        stateCode
        streetLine1
        streetLine2
        type
        uid
      }
      config {
        orderNotificationsType
      }
      identifier
      contact {
        email
        formalName
        phone
      }
    }
  }
`;
