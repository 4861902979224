import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTING_CONFIG } from '../constants';
import { Context } from '../store';
import { useBrand, useBrandTopSellingProducts } from '../hooks';
import { sessionCacheStorage } from '../services';
import { InfiniteScrollGrid, ProductsItem, ProductsWidget } from '../components';
import {
  getLocalStoreFrontSlug,
  getStateByZipCode,
  getBrandPageForGoogleAnalytics,
} from '../utils';
import NoImage from '../assets/no-image.png';
import { FacebookSecondIcon, InstagramSecondIcon, WebIcon } from '../components/icons/Icons';

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  bannerContainer: {
    width: '100%',
    height: 'auto',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  logoSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'start',
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  title: {
    fontSize: '25px',
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
    overflowWrap: 'break-word',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      fontSize: '29px',
      lineHeight: '29px',
    },
  },
  subTitle: {
    fontSize: '20px',
    lineHeight: '28px',
    color: 'var(--dark-gray)',
    fontWeight: 700,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  logo: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    margin: '20px 40px 20px 20px',
    zIndex: 1,
    boxShadow: '0px 0px 10px rgba(27, 27, 29, 0.15)',
    [theme.breakpoints.down('sm')]: {
      height: '130px',
      width: '130px',
      margin: 0,
    },
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    minHeight: '230px',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 400,
    color: 'var(--dark-gray)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: '13px',
      lineHeight: '22px',
      minHeight: 'auto',
    },
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    '& a': {
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(1),
    },
  },
  topSellingContainer: {
    marginTop: theme.spacing(8),
  },
}));

export const BrandPage = () => {
  const { state } = useContext(Context);
  const { brandSlug } = useParams();
  const storeFrontSlug = getLocalStoreFrontSlug();
  const { replace } = useHistory();
  const { topSellingBrandProducts } = useBrandTopSellingProducts(brandSlug);
  const classes = useStyles();
  const zipCode = state?.zipCode ?? '';
  const stateCode = getStateByZipCode(zipCode);
  const { products, brand, isLoading, isError, fetchListMore, hasMore } = useBrand({
    zipCode,
    stateCode,
    brandSlug,
  });

  const brandName = brand?.name ?? '';

  useEffect(() => {
    if (!isLoading && !isError) {
      getBrandPageForGoogleAnalytics(window, brand?.name, sessionCacheStorage.hasToken);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isLoading && !brand) {
      replace(ROUTING_CONFIG.brands.replace(':storeFrontSlug', storeFrontSlug));
    }
  }, [isLoading, brand]);

  const customTitle = (
    <>
      <div className={classes.titleContainer}>
        <div className={classes.bannerContainer}>
          {brand?.bannerImage?.url && (
            <img src={brand?.bannerImage?.url} alt={brandName} className={classes.image} />
          )}
        </div>
        <div className={classes.logoSection}>
          <img src={brand?.coverImage?.url || NoImage} alt={brandName} className={classes.logo} />
          <div className={classes.descriptionContainer}>
            <h1 className={classes.title}>{brandName}</h1>
            {brand?.description && <p dangerouslySetInnerHTML={{ __html: brand?.description }} />}
            <div className={classes.iconsContainer}>
              {brand?.websiteUrl && (
                <a href={brand?.websiteUrl} target="_blank" rel="noreferrer">
                  <WebIcon />
                </a>
              )}

              {brand?.facebookUrl && (
                <a href={brand?.facebookUrl} target="_blank" rel="noreferrer">
                  <FacebookSecondIcon />
                </a>
              )}
              {brand?.instagramUrl && (
                <a href={brand?.instagramUrl} target="_blank" rel="noreferrer">
                  <InstagramSecondIcon />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      {topSellingBrandProducts?.length ? (
        <div className={classes.topSellingContainer}>
          <ProductsWidget
            key="topSellingBrandProducts"
            items={topSellingBrandProducts}
            title="Top Selling Products"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );

  const subTitle = () => <h2 className={classes.subTitle}>All Products</h2>;

  return (
    <InfiniteScrollGrid
      itemComponent={ProductsItem}
      customTitle={customTitle}
      title={brandName}
      subTitle={subTitle()}
      data={products}
      isLoading={isLoading}
      isError={isError}
      fetchListMore={fetchListMore}
      hasMore={hasMore}
    />
  );
};
