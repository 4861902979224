import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidationCouponCodeSchema } from './use-validation-coupon-code-schema';

export const useCouponCodeForm = ({ onApplyCouponCode }) => {
  const validationSchema = useValidationCouponCodeSchema();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      code: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    onApplyCouponCode(data.code.toString().toUpperCase());
  };

  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
  };
};
