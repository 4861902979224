import * as Yup from 'yup';

export const useValidationLocationSchema = () =>
  Yup.object().shape({
    zipCode: Yup.string()
      .required('Required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Must be exactly 5 digits')
      .max(5, 'Must be exactly 5 digits'),
  });
