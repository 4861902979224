import React, { useRef } from 'react';
import clsx from 'clsx';
import { BUTTON_COLORS_TYPES, BUTTON_SIZES_TYPES } from '../../constants';

const colorStyles = {
  [BUTTON_COLORS_TYPES.primary]:
    'text-white bg-primary hover:bg-white hover:text-primary hover:shadow-rounded',
  [BUTTON_COLORS_TYPES.default]: 'text-gray-700 bg-gray-200 hover:bg-gray-300',
  [BUTTON_COLORS_TYPES.white]: 'text-gray-700 bg-white hover:bg-gray-100',
};

const maxWidthSizes = {
  [BUTTON_SIZES_TYPES.medium]: 'max-w-[250px]',
  [BUTTON_SIZES_TYPES.action]: 'max-w-[170px]',
};

export const Button = ({
  children,
  className,
  size = BUTTON_SIZES_TYPES.medium,
  color = BUTTON_COLORS_TYPES.primary,
  ...props
}) => {
  const colorClass = colorStyles[color];
  const maxWidthClass = maxWidthSizes[size];

  const buttonRef = useRef(null);

  const handleRipple = (event) => {
    const button = buttonRef.current;
    const rect = button.getBoundingClientRect();
    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    const rippleX = event.clientX - rect.left - radius;
    const rippleY = event.clientY - rect.top - radius;

    circle.style.width = `${diameter}px`;
    circle.style.height = `${diameter}px`;
    circle.style.left = `${rippleX}px`;
    circle.style.top = `${rippleY}px`;
    circle.classList.add('ripple');
    circle.classList.add('bg-primary');

    const existingRipple = button.getElementsByClassName('ripple')[0];
    if (existingRipple) {
      existingRipple.remove();
    }

    button.appendChild(circle);
  };

  return (
    <button
      ref={buttonRef}
      onClick={handleRipple}
      className={clsx(
        'relative h-9 w-full overflow-hidden rounded-[18px] px-2.5 py-1.5 text-xs font-semibold uppercase tracking-widest disabled:bg-gray-light disabled:text-gray-400',
        colorClass,
        maxWidthClass,
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
};
