import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT = gql`
  mutation updateBuyer(
    $contactInput: UpdateContactInput,
    $configInput: AccountConfigInput,
    $addressInput: AddressInput
    ) {
    updateBuyer(
      contactInput: $contactInput,
      configInput: $configInput,
      addressInput: $addressInput
      ) {
      addresses {
        city
        countryCode
        postalCode
        stateCode
        streetLine1
        streetLine2
        type
        uid
      }
      config {
        orderNotificationsType
      }
      contact {
        email
        formalName
        phone
      }
      identifier
    }
  }
`;
